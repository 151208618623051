<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="'modalUpdateUserCoin_' + user.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Cộng tiền User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="text-start" @submit.prevent="updateUser">
            <div class="mb-2">
              <label for="" class="form-label">Username</label>
              <input type="text" disabled :value="user.username" class="form-control" />
            </div>
            <div class="mb-2">
              <label for="" class="form-label">Số tiền ({{ amount | formatNumber }} xu)</label>
              <input type="number" v-model="amount" class="form-control" />
            </div>
            <div class="mb-2">
              <label for="" class="form-label">Lý do cộng tiền</label>
              <input type="text" v-model="description" class="form-control" />
            </div>
            <div class="text-center">
              <button class="btn btn-success py-1 w-50">Xác nhận</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from "@/api/user";
import { catchError } from "@/helpers";

export default {
  name: "UpdateUser",
  props: {
    user: Object,
  },
  data() {
    return {
      infos: { ...this.user },
      amount: null,
      description: null,
    };
  },
  watch: {
    user(value) {
      this.infos = { ...value };
    },
  },
  methods: {
    async updateUser() {
      const params = {
        amount: this.amount,
        username: this.user.username,
        description: this.description,
      };
      if (!this.amount) {
        this.$toastr.error("Vui lòng nhập số tiền !");
        return;
      }
      if (this.amount === 0 || this.amount === "0") {
        this.$toastr.error("Vui lòng nhập số tiền khác 0 !");
        return;
      }
      if (!this.description) {
        this.$toastr.error("Vui lòng nhập lý do cộng tiền !");
        return;
      }
      const response = await userApi.updateCoin(params);
      if (response.success) {
        this.$toastr.success(response.message);
        this.$emit("updateUserCoin", params);
      } else {
        this.$toastr.error(catchError(response));
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
