<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="'modalUpdateUser_' + user.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Cập nhật User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="text-start" @submit.prevent="updateUser">
            <div class="mb-2">
              <label for="" class="form-label">Username</label>
              <input type="text" disabled :value="user.username" class="form-control" />
            </div>
            <div class="mb-2">
              <label for="" class="form-label">Role</label>
              <select v-model="infos.role" class="form-select">
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="tester">Tester</option>
              </select>
            </div>
            <div class="mb-2">
              <label for="" class="form-label">Coin</label>
              <input type="number" v-model="infos.coin" class="form-control" />
            </div>
            <div class="mb-2">
              <label for="" class="form-label">Status</label>
              <select v-model="infos.status" class="form-select">
                <option value="0">0 (Hủy kích hoạt)</option>
                <option value="1">1 (Kích hoạt)</option>
              </select>
            </div>
            <div class="mb-2">
              <label for="" class="form-label">Name</label>
              <input type="text" v-model="infos.name" class="form-control" />
            </div>
            <div class="mb-2">
              <label for="" class="form-label">Email</label>
              <input type="email" v-model="infos.email" class="form-control" />
            </div>
            <div class="mb-2">
              <label for="" class="form-label">Phone</label>
              <input type="number" v-model="infos.phone" class="form-control" />
            </div>
            <div class="mb-3">
              <label for="" class="form-label">Password</label>
              <input type="password" v-model="password" class="form-control" autocomplete="off" />
            </div>
            <div class="text-center">
              <button class="btn btn-success py-1 w-50">Xác nhận</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from "@/api/user";

export default {
  name: "UpdateUser",
  props: {
    user: Object,
  },
  data() {
    return {
      infos: { ...this.user },
      password: null,
    };
  },
  watch: {
    user(value) {
      this.infos = { ...value };
    },
  },
  methods: {
    async updateUser() {
      const { id, coin, status, name, email, phone, role } = this.infos;
      const params = {
        id,
      };
      if (coin !== this.user.coin) {
        params.coin = coin;
      }
      if (status !== this.user.status) {
        params.status = status;
      }
      if (name !== this.user.name) {
        params.name = name;
      }
      if (email !== this.user.email) {
        params.email = email;
      }
      if (phone !== this.user.phone) {
        params.phone = phone;
      }
      if (role !== this.user.role) {
        params.role = role;
      }
      if (this.password) {
        params.password = this.password;
      }
      const arrs = Object.keys(params);
      if (arrs.length < 2) {
        this.$toastr.error("Chưa có thông nào được thay đổi !");
        return;
      }
      const response = await userApi.update(params);
      if (response.success) {
        this.$toastr.success(response.message);
        this.$emit("updateUser", params);
      } else {
        this.$toastr.error(response.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
