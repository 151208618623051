<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý User</h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý User</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <user-online-vue>
        <h6 slot="user-total" class="mb-2 mb-md-0 me-md-4">
          Tổng số User:
          <span class="badge bg-success font-size-14">{{ totalUsers | formatNumber }}</span>
        </h6>
      </user-online-vue>
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getUserList()" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo UserId</label>
                    <input type="text" class="form-control" v-model="userId" />
                  </div>
                </form>
                <form @submit.prevent="getUserList()" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo Username</label>
                    <input type="text" class="form-control" v-model="username" />
                  </div>
                </form>
                <form class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getUserList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
                <div class="col-md-2">
                  <a @click="getUserList()" class="btn btn-success mt-3" role="button">Xem thông tin tài khoản</a>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12 mb-4 mb-md-0"></div>
              </div>
              <div class="row mt-3" v-if="showUserToken">
                <div class="col-12 mb-4 mb-md-0">
                  <div>
                    <label for="" class="text-danger fw-bold"> User token (click để copy) </label>
                    <div @click="copyText(userToken)">
                      <input type="text" v-model="userToken" class="form-control" disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card text-left">
        <div class="card-body">
          <h4 class="card-title">Khóa tài khoản</h4>
          <div class="row mt-3">
            <div class="form-group col-md-2">
              <label for="">Username</label>
              <input type="text" class="form-control" v-model="username" />
            </div>
            <div class="form-group col-md-8">
              <label for="">Lý do khóa tài khoản</label>
              <input type="text" class="form-control" placeholder="Lý do khóa tài khoản"
                v-model="account_lock_reason" />
            </div>
            <div class="form-group col-md-2">
              <label for="">Số ngày khóa tài khoản</label>
              <input type="text" class="form-control" placeholder="Lý do khóa tài khoản" v-model="account_lock_day" />
            </div>
            <div class="form-group col-md-12 mt-3">
              <a @click="blockAccount()" class="btn btn-danger float-end" role="button">Khóa tài khoản</a>
              <a @click="unblockAccount()" class="btn btn-success float-end me-2" role="button">Mở Khóa tài khoản</a>
              <a @click="getListBlock()" class="btn btn-primary float-end me-2" role="button">Load danh sách bị khóa</a>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <datatable :stt="true" :title="'Danh sách User'" :columns="columns" :rows="manipulatedUsers"
            :defaultPerPage="10">
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center" slot-scope="props">
              <button class="btn btn-success font-10 py-1 btn-xs" data-bs-toggle="modal"
                :data-bs-target="'#modalUpdateUser_' + props.row.id">
                Update
              </button>
              <button class="btn btn-danger font-10 py-1 btn-xs ms-1" @click="resetPassword(props.row)">
                Reset Password
              </button>
              <button class="btn btn-info font-10 py-1 btn-xs ms-1" data-bs-toggle="modal"
                :data-bs-target="'#modalUpdateUserCoin_' + props.row.id">
                Cộng tiền
              </button>
              <update-vue :user="props.row" @updateUser="updateUser" />
              <update-coin-vue :user="props.row" @updateUserCoin="updateUserCoin" />
            </td>
          </datatable>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <datatable :stt="true" :title="'Users Bị Khóa'" :columns="blockUserTableColumns" :rows="blockUsers"
            :defaultPerPage="10">
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center" slot-scope="props">
              <button @click="unblockAccount(props.row.username)" class="btn btn-danger font-10 py-1 btn-xs ms-1">
                Mở khóa
              </button>
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import userApi from "@/api/user";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import UpdateVue from "./Update.vue";
import { buildParamQuery } from "@/helpers/index";
import UpdateCoinVue from "./UpdateCoin.vue";
import UserOnlineVue from "@/components/user/UserOnline.vue";

export default {
  name: "User",
  components: {
    Datatable,
    LoadingVue,
    UpdateVue,
    UpdateCoinVue,
    UserOnlineVue,
  },
  data() {
    return {
      username: null,
      userId: null,
      limit: 50,
      users: [],
      loading: false,
      totalUsers: 0,
      showUserToken: false,
      userToken: null,
      account_lock_reason:
        "Vi phạm chính sách AutoGolike, Cố ý cheat trong khi làm việc, báo cáo hoàn thành ảo",
      account_lock_day: 10000,
      userColumns: [
        { label: "Id", field: "id" },
        { label: "Name", field: "name" },
        { label: "Username", field: "username" },
        { label: "Role", field: "role" },
        { label: "Coin", field: "coin", numeric: true },
        { label: "FB Accounts", field: "total_fbs", numeric: true },
        { label: "Status", field: "status_name" },
        { label: "Email", field: "email" },
        { label: "Phone", field: "phone" },
        { label: "Referral", field: "referral" },
        { label: "Ngày tạo", field: "created_at", timeago: true },
      ],
      columns: [],
      blockUserTableColumns: [
        { label: "Id", field: "id" },
        { label: "Name", field: "username" },
        { label: "Mô tả", field: "description", input: true },
        { label: "Type", field: "type" },
        { label: "Coin", field: "coin", numeric: true },
        { label: "Pending Coin", field: "pending_coin", numeric: true },
        { label: "Ip", field: "ip" },
        { label: "Ngày mở", field: "open_at" },
        { label: "Ngày tạo", field: "created_at", timeago: true },
      ],
    };
  },
  computed: {
    manipulatedUsers() {
      return this.users.map((user) => {
        user.status_name = this.statusName(user.status);
        return user;
      });
    },
    blockUsers() {
      return this.users.filter((user) => user.blacklist).map((user) => user.blacklist);
    },
  },
  created() {
    this.getUserList();
  },
  methods: {
    async blockAccount() {
      const { username, account_lock_reason: description, account_lock_day: days } = this;
      const params = {
        username,
        description,
        days,
      };
      console.log(params);
      const response = await userApi.block(params);
      if (response?.success) {
        this.$toastr.success(`Khóa thành công tài khoản ${username}`);
      } else {
        this.$toastr.error(`Khóa thất bại tài khoản ${username}: ${response?.message}`);
      }
    },
    async unblockAccount(username = null) {
      if (!username) {
        username = this.username;
      }
      const params = { username };
      const response = await userApi.unblock(params);
      if (response?.success) {
        await this.getUserList();
        this.$toastr.success(`Mở Khóa thành công tài khoản ${username}`);
      } else {
        this.$toastr.error(`Mở Khóa thất bại tài khoản ${username}: ${response?.message}`);
      }
    },
    async getListBlock() {
      const response = await userApi.listBlock();
      if (response?.success) {
        this.columns = [
          { label: "Id", field: "id" },
          { label: "Username", field: "username" },
          { label: "Coin", field: "coin", numeric: true },
          { label: "Ngày tạo", field: "created_at", timeago: true },
          { label: "Pending Coin", field: "pending_coin", numeric: true },
          { label: "Lý do khóa", field: "description" },
        ];
        this.$toastr.success(`Load danh sách tài khoản bị khóa thành công tài khoản`);
        this.users = response.data;
        this.totalUsers = response.total;
      } else {
        this.$toastr.error(
          `Load danh sách tài khoản bị khóa thất bại tài khoản: ${response?.message}`
        );
      }
    },
    async getUserList() {
      this.columns = this.userColumns;

      const params = {
        limit: this.limit,
        username: this.username,
        id: this.userId,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await userApi.list(queries);
      if (params.username && params.username !== "") {
        this.getUserToken();
      } else {
        this.showUserToken = false;
      }
      this.loading = false;
      if (response?.success) {
        this.users = response.data;
        this.totalUsers = response.total;
      } else {
        this.$toastr.error(response?.message || "Thất bại");
      }
    },
    async getUserToken() {
      const params = {
        username: this.username,
      };
      const response = await userApi.getToken(params);
      if (response?.success) {
        this.userToken = response?.token;
        this.showUserToken = true;
      } else {
        this.showUserToken = false;
        this.$toastr.error(catchError(response));
      }
    },
    copyText(value) {
      if (value && value !== "") {
        this.$copyText(value)
          .then(() => {
            this.$toastr.success("Copy thành công !");
          })
          .catch(() => {
            this.$toastr.error("Copy thất bại !");
          });
      }
    },
    async resetPassword(user) {
      const params = {
        username: user.username,
      };
      const response = await userApi.resetPassword(params);
      if (response?.success) {
        this.$swal("Thành công", response?.message || "Thành công", "success");
      } else {
        this.$toastr.error(response?.message || "Thất bại");
      }
    },
    updateUser(params) {
      const { id, coin, status, name, email, phone } = params;
      // params: {id, coin, status, name, email, phone}
      this.users = this.users.map((user) => {
        if (user.id !== id) {
          return user;
        } else {
          if (coin) {
            user.coin = coin;
          }
          if (status) {
            user.status = status;
          }
          if (name) {
            user.name = name;
          }
          if (email) {
            user.email = email;
          }
          if (phone) {
            user.phone = phone;
          }
          return user;
        }
      });
    },
    updateUserCoin(params) {
      const { username, amount } = params;
      this.users = this.users.map((user) => {
        if (user.username !== username) {
          return user;
        } else {
          if (amount) {
            const parsedAmount = parseInt(amount);
            user.coin = user.coin + parsedAmount;
          }
          return user;
        }
      });
    },
    statusName(id) {
      switch (id) {
        case 1:
          return "Đã kích hoạt";
        case "1":
          return "Đã kích hoạt";
        case 0:
          return "Chưa kích hoạt";
        case "0":
          return "Chưa kích hoạt";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
